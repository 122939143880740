module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Roboto","DroidSansMono","AkzidenzGrotesk","PlayfairDisplay","AkzidenzGrotesk-Regular","kneicon"],"urls":["/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"knecht-reisen","short_name":"knecht","description":"Offcial knecht reisen website","start_url":"/","background_color":"#B2B2B2","theme_color":"#B2B2B2","lang":"de","display":"standalone","icon":"src/images/knecht-favicon-192x192.png","include_favicon":false,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"356ba690ce2ce51ca2a28bc11b2f4864"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://68e8fdaffd1244459324e40125aace58@o406152.ingest.sentry.io/5726199"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
